import { v4 as uuidv4 } from 'uuid';

const TYPE = Object.freeze({
  BONDS: 'bonds',
  DEBT_SECURITIES: 'debt-securities',
  FUNDS: 'funds',
  SHARES: 'shares',
});

const LABEL = Object.freeze({
  [TYPE.BONDS]: 'Obbligazioni',
  [TYPE.DEBT_SECURITIES]: 'Titoli di debito',
  [TYPE.FUNDS]: 'Fondi',
  [TYPE.SHARES]: 'Azioni',
});

const WIZARD = Object.freeze({
  KEY: {
    CONTRACT: 'contract',
    DOCS: 'docs',
    HOLDERS: 'holders',
    ID: 'id',
    INFO: 'info',
    TRANSFERABILITY: 'transferability',
  },
});

const CONTENT = Object.freeze({
  [WIZARD.KEY.DOCS]: [
    {
      id: uuidv4(),
      title: 'Prospetto Informativo',
      description:
        'Documento dettagliato che descrive il fondo, la sua politica di investimento, i rischi associati, le spese, le procedure di sottoscrizione e rimborso delle quote, e altre informazioni essenziali per gli investitori.',
    },
    {
      id: uuidv4(),
      title: 'Regolamento di Gestione',
      description:
        'Regole specifiche relative alla gestione del fondo, compresi i criteri di investimento, le strategie di asset allocation, le condizioni di ammissibilità degli investimenti, e le politiche di valutazione e di ripartizione dei rendimenti.',
    },
    {
      id: uuidv4(),
      title: 'Documenti di Distribuzione',
      description:
        'Documento che descrive come le quote del fondo saranno offerte e vendute agli investitori, inclusi dettagli su eventuali agenti di vendita o distributori.',
    },
    {
      id: uuidv4(),
      title: 'Altro',
      description:
        'Documentazione preparata da consulenti legali che attesta la conformità del fondo alle normative applicabili.',
    },
  ],
});

export default Object.freeze({
  CONTENT,
  LABEL,
  TYPE,
  WIZARD,
});
