const PATH = Object.freeze({
  ROOT: '/',
  NOT_FOUND: '/:notFound(.*)',
  LOGIN: '/login',
  HOME: '/home',
  BANK: '/bank',
  BANK_BALANCE: 'balance',
  BANK_RECEIVED: 'received',
  BANK_SEND: 'send',
  BANK_SENT: 'sent',
  CW20: '/cw20',
  CW20_RECEIVED: 'received',
  CW20_SEND: 'send',
  CW20_SENT: 'sent',
  EREG: '/ereg',
  EREG_AUTHORITIES: 'authorities',
  EREG_CONSTRAINT_REGISTRY: 'constraint-registry',
  EREG_TRANSACTION_REGISTRY: 'transaction-registry',
  EREG_USER_REGISTRY: 'user-registry',
  MY_ASSETS: '/my-assets',
  MY_ASSETS_DASHBOARD: 'dashboard',
  MY_ASSETS_MANAGE: 'manage/:id',
  MY_ASSETS_MANAGE_AUTH: 'auth',
  MY_ASSETS_MANAGE_CONFIRM: 'confirm',
  MY_ASSETS_MANAGE_DASHBOARD: 'dashboard',
  MY_ASSETS_MANAGE_SELL: 'sell',
  TOOL_REGISTRY: '/tool-registry',
  TOOL_REGISTRY_CREATE: 'create',
  TOOL_REGISTRY_CREATE_CONFIRM: 'confirm',
  TOOL_REGISTRY_CREATE_DOCS: 'docs',
  TOOL_REGISTRY_CREATE_HOLDERS: 'holders',
  TOOL_REGISTRY_CREATE_MAIN_INFO: 'main-info',
  TOOL_REGISTRY_CREATE_TRANSFERABILITY: 'transferability',
  TOOL_REGISTRY_CREATE_TYPE: 'type',
  TOOL_REGISTRY_DASHBOARD: 'dashboard',
  TOOL_REGISTRY_EDIT: 'edit/:id',
  TOOL_REGISTRY_EDIT_DOCS: 'docs',
  TOOL_REGISTRY_EDIT_HOLDERS: 'holders',
  TOOL_REGISTRY_EDIT_MAIN_INFO: 'main-info',
  TOOL_REGISTRY_EDIT_TRANSFERABILITY: 'transferability',
  USERS: '/users',
  USERS_DETAIL: 'detail',
  USERS_CREATE: 'create',
});

const NAME = Object.freeze({
  NOT_FOUND: 'not-found',
  LOGIN: 'login',
  HOME: 'home',
  BANK: 'bank',
  BANK_BALANCE: 'bank-balance',
  BANK_RECEIVED: 'bank-received',
  BANK_SEND: 'bank-send',
  BANK_SENT: 'bank-sent',
  CW20: 'cw20',
  CW20_RECEIVED: 'cw20-received',
  CW20_SEND: 'cw20-send',
  CW20_SENT: 'cw20-sent',
  EREG: 'ereg',
  EREG_AUTHORITIES: 'ereg-authorities',
  EREG_CONSTRAINT_REGISTRY: 'ereg-constraint-registry',
  EREG_TRANSACTION_REGISTRY: 'ereg-transaction-registry',
  EREG_USER_REGISTRY: 'ereg-user-registry',
  MY_ASSETS: 'my-assets',
  MY_ASSETS_DASHBOARD: 'my-assets-dashboard',
  MY_ASSETS_MANAGE: 'my-assets-manage',
  MY_ASSETS_MANAGE_AUTH: 'my-assets-manage-auth',
  MY_ASSETS_MANAGE_CONFIRM: 'my-assets-manage-confirm',
  MY_ASSETS_MANAGE_DASHBOARD: 'my-assets-manage-dashboard',
  MY_ASSETS_MANAGE_SELL: 'my-assets-manage-sell',
  TOOL_REGISTRY: 'tool-registry',
  TOOL_REGISTRY_CREATE: 'tool-registry-create',
  TOOL_REGISTRY_CREATE_CONFIRM: 'tool-registry-create-confirm',
  TOOL_REGISTRY_CREATE_DOCS: 'tool-registry-create-docs',
  TOOL_REGISTRY_CREATE_HOLDERS: 'tool-registry-create-holders',
  TOOL_REGISTRY_CREATE_MAIN_INFO: 'tool-registry-create-main-info',
  TOOL_REGISTRY_CREATE_TRANSFERABILITY: 'tool-registry-create-transferability',
  TOOL_REGISTRY_CREATE_TYPE: 'tool-registry-create-type',
  TOOL_REGISTRY_DASHBOARD: 'tool-registry-dashboard',
  TOOL_REGISTRY_EDIT: 'tool-registry-edit',
  TOOL_REGISTRY_EDIT_DOCS: 'tool-registry-edit-docs',
  TOOL_REGISTRY_EDIT_HOLDERS: 'tool-registry-edit-holders',
  TOOL_REGISTRY_EDIT_MAIN_INFO: 'tool-registry-edit-main-info',
  TOOL_REGISTRY_EDIT_TRANSFERABILITY: 'tool-registry-edit-transferability',
  USERS: 'users',
  USERS_DETAIL: 'users-detail',
  USERS_CREATE: 'users-create',
});

const AUTH = Object.freeze({
  USER_ROLE: {
    ANY: 'any',
    AUTHORIZED: 'authorized',
    NOT_AUTHORIZED: 'notAuthorized',
  },
});

export default Object.freeze({
  AUTH,
  NAME,
  PATH,
});
