import {
  mockConstraintRegistryItems,
  mockConstraintRegistrySummary,
} from '@/__mocks__';

const constraintRegistryApi = {
  requestList() {
    return new Promise((resolve) => {
      const constraints = 10;
      setTimeout(() => {
        const items = {
          data: {
            constraints: mockConstraintRegistryItems(constraints),
            paging: {
              next_cursor: 'MjAyMS0wOC0wMVQxNToyNTo0OS4zODg3MjNa',
              next_link:
                'https://localhost/items?cursor=MjAyMS0wOC0wMVQxNToyNTo0OS4zODg3MjNa',
              total_count: constraints,
            },
          },
        };
        resolve(items);
      }, 100);
    });
  },
  requestSummary() {
    return new Promise((resolve) => {
      setTimeout(() => {
        const summary = { data: mockConstraintRegistrySummary() };
        resolve(summary);
      }, 100);
    });
  },
};

export default constraintRegistryApi;
