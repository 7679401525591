import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import BaseDragArea from '@/components/BaseDragArea.vue';
import BaseErrorMessage from '@/components/BaseErrorMessage.vue';
import BaseHeaderBox from '@/components/BaseHeaderBox.vue';
import BaseLinkItem from '@/components/BaseLinkItem.vue';
import BaseTextField from '@/components/BaseTextField.vue';
import TheDashboardHeader from '@/components/TheDashboardHeader.vue';
import TheErrorMessage from '@/components/TheErrorMessage.vue';
import TheExecuteButton from '@/components/TheExecuteButton.vue';
import TheFramedWindow from '@/components/TheFramedWindow.vue';
import TheJsonViewer from '@/components/TheJsonViewer.vue';
import TheManageItemButtons from '@/components/TheManageItemButtons.vue';
import TheParamsForm from '@/components/TheParamsForm.vue';
import ThePaymentMethodChoice from '@/components/ThePaymentMethodChoice.vue';
import TheSearchBar from '@/components/search-bar/TheSearchBar.vue';
import TheSendTokensForm from '@/components/TheSendTokensForm.vue';
import TheWizardWindow from '@/components/TheWizardWindow.vue';

Vue.component('base-drag-area', BaseDragArea);
Vue.component('base-error-message', BaseErrorMessage);
Vue.component('base-header-box', BaseHeaderBox);
Vue.component('base-link-item', BaseLinkItem);
Vue.component('base-text-field', BaseTextField);
Vue.component('the-dashboard-header', TheDashboardHeader);
Vue.component('the-error-message', TheErrorMessage);
Vue.component('the-execute-button', TheExecuteButton);
Vue.component('the-framed-window', TheFramedWindow);
Vue.component('the-json-viewer', TheJsonViewer);
Vue.component('the-manage-item-buttons', TheManageItemButtons);
Vue.component('the-params-form', TheParamsForm);
Vue.component('the-payment-method-choice', ThePaymentMethodChoice);
Vue.component('the-search-bar', TheSearchBar);
Vue.component('the-send-tokens-form', TheSendTokensForm);
Vue.component('the-wizard-window', TheWizardWindow);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
