import {
  mockToolRegistryItems,
  mockToolRegistrySummary,
  mockToolRegistryTypes,
} from '@/__mocks__';

const toolRegistryApi = {
  requestList() {
    return new Promise((resolve) => {
      const tools = 10;
      setTimeout(() => {
        const items = {
          data: {
            tools: mockToolRegistryItems(tools),
            paging: {
              next_cursor: 'MjAyMS0wOC0wMVQxNToyNTo0OS4zODg3MjNa',
              next_link:
                'https://localhost/items?cursor=MjAyMS0wOC0wMVQxNToyNTo0OS4zODg3MjNa',
              total_count: tools,
            },
          },
        };
        resolve(items);
      }, 100);
    });
  },
  requestSummary() {
    return new Promise((resolve) => {
      setTimeout(() => {
        const summary = { data: mockToolRegistrySummary() };
        resolve(summary);
      }, 100);
    });
  },
  requestTypes() {
    return new Promise((resolve) => {
      setTimeout(() => {
        const items = {
          data: {
            types: mockToolRegistryTypes(),
          },
        };
        resolve(items);
      }, 100);
    });
  },
};

export default toolRegistryApi;
