import { fakerIT as faker } from '@faker-js/faker';

const mockUserRegistrySummary = () => {
  const item = {
    approved: faker.number.int({ max: 5000, min: 1000 }),
    done: faker.number.int({ max: 5, min: 1 }),
    rejected: faker.number.int({ max: 500, min: 100 }),
    today: faker.number.int({ max: 50, min: 1 }),
    todo: faker.number.int({ max: 10, min: 1 }),
  };
  return item;
};

const mockUserRegistryItem = () => {
  const item = {
    address: faker.location.streetAddress({ useFullAddress: true }),
    cellphone: faker.phone.number(),
    created_at: faker.date.soon({ refDate: '2024-01-01T00:00:00.000Z' }),
    email: faker.internet.email(),
    id: faker.string.uuid(),
    name: faker.person.fullName(),
    phone: faker.phone.number(),
  };
  return item;
};

const mockUserRegistryItems = (items = 3) => {
  return faker.helpers.multiple(mockUserRegistryItem, {
    count: items,
  });
};

export { mockUserRegistrySummary, mockUserRegistryItem, mockUserRegistryItems };
