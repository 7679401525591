import {
  mdiSortAscending,
  mdiSortDescending,
  mdiViewGridOutline,
  mdiViewListOutline,
} from '@mdi/js';

const COLOR = Object.freeze({
  AMBER: 'amber',
  BLUE: 'blue',
  BLUE_GREY: 'blue-grey',
  CYAN: 'cyan',
  DEEP_ORANGE: 'deep-orange',
  DEEP_PURPLE: 'deep-purple',
  GREEN: 'green',
  GREY: 'grey',
  INDIGO: 'indigo',
  LIGHT_BLUE: 'light-blue',
  LIGHT_GREEN: 'light-green',
  LIME: 'lime',
  ORANGE: 'orange',
  PINK: 'pink',
  PURPLE: 'purple',
  RED: 'red',
  TEAL: 'teal',
  YELLOW: 'yellow',
});

const POSITIONINGS = Object.freeze({
  GRID: 'grid',
  LIST: 'list',
});

const SORTINGS = Object.freeze({
  ASC: 'asc',
  DESC: 'desc',
});
const ICONS = Object.freeze({
  [POSITIONINGS.GRID]: mdiViewGridOutline,
  [POSITIONINGS.LIST]: mdiViewListOutline,
  [SORTINGS.ASC]: mdiSortAscending,
  [SORTINGS.DESC]: mdiSortDescending,
});

const PAYMENT_METHOD = Object.freeze({
  BANK_TRANSFER: 'bank_transfer',
  CREDIT_CARD: 'credit_card',
});

export default Object.freeze({
  COLOR,
  ICONS,
  PAYMENT_METHOD,
  POSITIONINGS,
  SORTINGS,
});
