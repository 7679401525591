import { initState } from './index';

export default {
  reset(state) {
    Object.assign(state, initState());
  },
  setError(state, error) {
    state.error = error;
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setAdding(state, payload) {
    state.isAdding = payload;
  },
  addConstraints(state, payload) {
    state.list.push(...payload);
  },
  setPaging(state, payload) {
    state.paging = payload;
  },
  setSummary(state, payload) {
    state.summary = payload;
  },
};
