<template>
  <v-text-field dense outlined :disabled="disabled" v-model="model">
    <template #label>
      <span v-text="label" />
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: 'BaseTextField',
  props: ['value', 'label', 'disabled'],
  emits: ['input'],
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
