import { fakerIT as faker } from '@faker-js/faker';

const mockAuthoritiesSummary = () => {
  const item = {
    active: faker.number.int({ max: 10, min: 1 }),
    carried_out: faker.number.int({ max: 500, min: 100 }),
    forced: faker.number.int({ max: 10, min: 1 }),
    suspended: faker.number.int({ max: 5, min: 1 }),
    users: faker.number.int({ max: 5000, min: 1000 }),
  };
  return item;
};

const mockAuthoritiesItem = () => {
  const item = {
    address: faker.location.streetAddress({ useFullAddress: true }),
    cellphone: faker.phone.number(),
    created_at: faker.date.soon({ refDate: '2024-01-01T00:00:00.000Z' }),
    email: faker.internet.email(),
    id: faker.string.uuid(),
    name: faker.helpers.arrayElement(['CONSOB']),
    phone: faker.phone.number(),
  };
  return item;
};

const mockAuthoritiesItems = (items = 3) => {
  return faker.helpers.multiple(mockAuthoritiesItem, {
    count: items,
  });
};

export { mockAuthoritiesSummary, mockAuthoritiesItem, mockAuthoritiesItems };
