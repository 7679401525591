import { fakerIT as faker } from '@faker-js/faker';

const mockConstraintRegistrySummary = () => {
  const item = {
    active: faker.number.int({ max: 5000, min: 1000 }),
    extinct: faker.number.int({ max: 10, min: 1 }),
    total: faker.number.int({ max: 10, min: 1 }),
    value: faker.number.int({ max: 500, min: 100 }),
  };
  return item;
};

const mockConstraintRegistryItem = () => {
  const item = {
    color: faker.internet.color(),
    created_at: faker.date.soon({ refDate: '2024-01-01T00:00:00.000Z' }),
    executed_on: faker.date.recent({ refDate: '2024-02-01T00:00:00.000Z' }),
    from_name: faker.person.fullName(),
    id: faker.string.uuid(),
    to_name: faker.helpers.arrayElement([
      'Banca del Salento',
      'Tribunale di Bologna',
      'Prestiti veloci',
    ]),
    tx_hash: faker.string.alphanumeric(64),
  };
  return item;
};

const mockConstraintRegistryItems = (items = 3) => {
  return faker.helpers.multiple(mockConstraintRegistryItem, {
    count: items,
  });
};

export {
  mockConstraintRegistrySummary,
  mockConstraintRegistryItem,
  mockConstraintRegistryItems,
};
