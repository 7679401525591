import { fakerIT as faker } from '@faker-js/faker';
import { LISTINGS, TOOL_REGISTRY } from '@/constants';

const colors = Object.values(LISTINGS.COLOR);
const types = Object.values(TOOL_REGISTRY.TYPE);

const mockMyAssetsSummary = () => {
  const item = {
    apr: faker.number.int({ max: 9, min: 1 }),
    txs: faker.number.int({ max: 19, min: 10 }),
    value: faker.number.int({ max: 499, min: 100 }),
  };
  return item;
};

const mockMyAssetsItem = () => {
  const color = faker.helpers.arrayElement(colors);
  const type = faker.helpers.arrayElement(types);
  const item = {
    color,
    created_at: faker.date.soon({ refDate: '2024-01-01T00:00:00.000Z' }),
    id: faker.string.uuid(),
    name: faker.company.name(),
    positive: faker.datatype.boolean(),
    total: faker.number.int({ max: 999999, min: 1000 }),
    type: TOOL_REGISTRY.LABEL[type],
    value: faker.number.int({ max: 999, min: 1 }),
  };
  return item;
};

const mockMyAssetsItems = (items = 3) => {
  return faker.helpers.multiple(mockMyAssetsItem, {
    count: items,
  });
};

const mockMyAssetsManageSummary = () => {
  const item = {
    apr: faker.number.int({ max: 9, min: 1 }),
    price: faker.number.int({ max: 9, min: 1 }),
    titles: faker.number.int({ max: 999, min: 100 }),
    value: faker.number.int({ max: 999, min: 100 }),
  };
  return item;
};

const mockMyAssetsManageItem = () => {
  const color = faker.helpers.arrayElement(colors);
  const item = {
    color,
    created_at: faker.date.soon({ refDate: '2024-01-01T00:00:00.000Z' }),
    id: faker.string.uuid(),
    total: faker.number.int({ max: 999, min: 10 }),
    tx_hash: faker.string.alphanumeric(64),
    tx_type: 'Acquisto',
    value: faker.number.int({ max: 99, min: 1 }),
  };
  return item;
};
const mockMyAssetsManageItems = (items = 3) => {
  return faker.helpers.multiple(mockMyAssetsManageItem, {
    count: items,
  });
};

export {
  mockMyAssetsItem,
  mockMyAssetsItems,
  mockMyAssetsManageItem,
  mockMyAssetsManageItems,
  mockMyAssetsManageSummary,
  mockMyAssetsSummary,
};
