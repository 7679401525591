import { ROUTE } from '@/constants';
import { loadView } from '../index';

const EregAuthoritiesView = loadView('ereg/EregAuthoritiesView');
const EregConstraintRegistryView = loadView('ereg/EregConstraintRegistryView');
const EregTransactionRegistryView = loadView(
  'ereg/EregTransactionRegistryView',
);
const EregUserRegistryView = loadView('ereg/EregUserRegistryView');

const eregRoute = {
  path: ROUTE.PATH.EREG,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
  },
  children: [
    {
      path: '',
      name: ROUTE.NAME.EREG,
      component: EregUserRegistryView,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.EREG_AUTHORITIES,
      name: ROUTE.NAME.EREG_AUTHORITIES,
      component: EregAuthoritiesView,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.EREG_CONSTRAINT_REGISTRY,
      name: ROUTE.NAME.EREG_CONSTRAINT_REGISTRY,
      component: EregConstraintRegistryView,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.EREG_TRANSACTION_REGISTRY,
      name: ROUTE.NAME.EREG_TRANSACTION_REGISTRY,
      component: EregTransactionRegistryView,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.EREG_USER_REGISTRY,
      name: ROUTE.NAME.EREG_USER_REGISTRY,
      component: EregUserRegistryView,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
      },
    },
  ],
};

export default eregRoute;
