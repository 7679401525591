import { fakerIT as faker } from '@faker-js/faker';
import { LISTINGS, TOOL_REGISTRY } from '@/constants';

const colors = Object.values(LISTINGS.COLOR);
const types = Object.values(TOOL_REGISTRY.TYPE);

const mockToolRegistrySummary = () => {
  const item = {
    companies: faker.number.int({ max: 10, min: 1 }),
    registered: faker.number.int({ max: 5000, min: 1000 }),
    suspended: faker.number.int({ max: 100, min: 10 }),
    total: faker.number.int({ max: 5, min: 1 }),
    value: faker.number.int({ max: 500, min: 100 }),
  };
  return item;
};

const mockToolRegistryItem = () => {
  const type = faker.helpers.arrayElement(types);
  const item = {
    color: faker.helpers.arrayElement(colors),
    created_at: faker.date.soon({ refDate: '2024-01-01T00:00:00.000Z' }),
    id: faker.string.uuid(),
    issued_on: faker.date.recent({ refDate: '2024-02-01T00:00:00.000Z' }),
    name: faker.company.name(),
    tx_hash: faker.string.alphanumeric(64),
    type: TOOL_REGISTRY.LABEL[type],
  };
  return item;
};

const mockToolRegistryItems = (items = 3) => {
  return faker.helpers.multiple(mockToolRegistryItem, {
    count: items,
  });
};

const mockToolRegistryTypes = () => {
  const items = [
    {
      color: faker.helpers.arrayElement(colors),
      created_at: faker.date.soon({ refDate: '2024-01-01T00:00:00.000Z' }),
      description:
        'Obbligazioni emesse da società per azioni (SPA) per finanziarsi sul mercato, promettendo il rimborso del capitale più interessi.',
      id: faker.string.uuid(),
      name: TOOL_REGISTRY.LABEL[TOOL_REGISTRY.TYPE.BONDS],
    },
    {
      color: faker.helpers.arrayElement(colors),
      created_at: faker.date.soon({ refDate: '2024-01-01T00:00:00.000Z' }),
      description:
        'Strumenti di debito di medio-lungo termine emessi da società a responsabilità limitata (SRL) per raccogliere finanziamenti, con pagamento di interessi e rimborso del capitale.',
      id: faker.string.uuid(),
      name: TOOL_REGISTRY.LABEL[TOOL_REGISTRY.TYPE.DEBT_SECURITIES],
    },
    {
      color: faker.helpers.arrayElement(colors),
      created_at: faker.date.soon({ refDate: '2024-01-01T00:00:00.000Z' }),
      description:
        'Partecipazioni in fondi di investimento collettivo del risparmio (OICR), che raccolgono capitali da investire in diversi asset per conto degli investitori.',
      id: faker.string.uuid(),
      name: TOOL_REGISTRY.LABEL[TOOL_REGISTRY.TYPE.FUNDS],
    },
    {
      color: faker.helpers.arrayElement(colors),
      created_at: faker.date.soon({ refDate: '2024-01-01T00:00:00.000Z' }),
      description:
        'Titoli di proprietà che rappresentano una quota del capitale sociale di una società per azioni (SPA), conferendo diritti di voto e una parte degli utili.',
      id: faker.string.uuid(),
      name: TOOL_REGISTRY.LABEL[TOOL_REGISTRY.TYPE.SHARES],
    },
  ];
  return items.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
};

export {
  mockToolRegistrySummary,
  mockToolRegistryItem,
  mockToolRegistryItems,
  mockToolRegistryTypes,
};
