import { toolRegistryApi } from '@/apis';

export default {
  async intiToolRegistryCreate({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchTypes')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async fetchTypes({ commit }) {
    try {
      const response = await toolRegistryApi.requestTypes();
      commit('setTypes', response.data.types);
    } catch (error) {
      commit('setError', error);
    }
  },
  saveProgress({ commit }, payload) {
    commit('setProgress', payload);
    return true;
  },
};
