import { authoritiesApi } from '@/apis';

export default {
  async initEregAuthorities({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchList'), dispatch('fetchSummary')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async incrementAuthorities({ commit, dispatch }) {
    commit('setAdding', true);
    await dispatch('fetchList');
    commit('setAdding', false);
  },
  async fetchList({ commit }) {
    try {
      const response = await authoritiesApi.requestList();
      commit('addAuthorities', response.data.authorities);
      commit('setPaging', response.data.paging);
    } catch (error) {
      commit('setError', error);
    }
  },
  async fetchSummary({ commit }) {
    try {
      const response = await authoritiesApi.requestSummary();
      commit('setSummary', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
};
