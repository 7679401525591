import { initState } from './index';

export default {
  reset(state) {
    Object.assign(state, initState());
  },
  setError(state, error) {
    state.error = error;
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setProgress(state, payload) {
    if (!state.progress) {
      state.progress = {};
    }
    state.progress[payload.key] = undefined;
    state.progress = {
      ...state.progress,
      [payload.key]: payload.value,
    };
  },
  setTypes(state, payload) {
    state.types = payload;
  },
};
