<template>
  <v-radio-group row v-model="selected">
    <v-radio label="Bonifico" :value="LISTINGS.PAYMENT_METHOD.BANK_TRANSFER">
    </v-radio>
    <v-radio
      label="Carta di credito"
      :value="LISTINGS.PAYMENT_METHOD.CREDIT_CARD"
    ></v-radio>
  </v-radio-group>
</template>

<script>
import { LISTINGS } from '@/constants';

export default {
  name: 'ThePaymentMethodChoice',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      LISTINGS,
    };
  },
  emits: ['input'],
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
