import { ROUTE } from '@/constants';
import store from '@/store';
import { loadView } from '../index';

const ToolRegistryCreateRouter = loadView(
  'tool-registry/ToolRegistryCreateRouter',
);
const ToolRegistryCreateConfirmView = loadView(
  'tool-registry/create/ToolRegistryCreateConfirmView',
);
const ToolRegistryCreateDocsView = loadView(
  'tool-registry/create/ToolRegistryCreateDocsView',
);
const ToolRegistryCreateHoldersView = loadView(
  'tool-registry/create/ToolRegistryCreateHoldersView',
);
const ToolRegistryCreateMainInfoView = loadView(
  'tool-registry/create/ToolRegistryCreateMainInfoView',
);
const ToolRegistryCreateTransferabilityView = loadView(
  'tool-registry/create/ToolRegistryCreateTransferabilityView',
);
const ToolRegistryCreateTypeView = loadView(
  'tool-registry/create/ToolRegistryCreateTypeView',
);
const ToolRegistryDashboardView = loadView(
  'tool-registry/ToolRegistryDashboardView',
);
const ToolRegistryEditRouter = loadView('tool-registry/ToolRegistryEditRouter');
const ToolRegistryEditDocsView = loadView(
  'tool-registry/edit/ToolRegistryEditDocsView',
);
const ToolRegistryEditHoldersView = loadView(
  'tool-registry/edit/ToolRegistryEditHoldersView',
);
const ToolRegistryEditMainInfoView = loadView(
  'tool-registry/edit/ToolRegistryEditMainInfoView',
);
const ToolRegistryEditTransferabilityView = loadView(
  'tool-registry/edit/ToolRegistryEditTransferabilityView',
);

const toolRegistryRoute = {
  path: ROUTE.PATH.TOOL_REGISTRY,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
  },
  children: [
    {
      path: '',
      name: ROUTE.NAME.TOOL_REGISTRY,
      component: ToolRegistryDashboardView,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.TOOL_REGISTRY_CREATE,
      component: ToolRegistryCreateRouter,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
      },
      children: [
        {
          path: '',
          name: ROUTE.NAME.TOOL_REGISTRY_CREATE,
          component: ToolRegistryCreateTypeView,
          meta: {
            auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
          },
        },
        {
          path: ROUTE.PATH.TOOL_REGISTRY_CREATE_CONFIRM,
          name: ROUTE.NAME.TOOL_REGISTRY_CREATE_CONFIRM,
          component: ToolRegistryCreateConfirmView,
          beforeEnter: (_to, _from, next) => {
            restartWizard(next);
          },
          meta: {
            auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
          },
        },
        {
          path: ROUTE.PATH.TOOL_REGISTRY_CREATE_DOCS,
          name: ROUTE.NAME.TOOL_REGISTRY_CREATE_DOCS,
          component: ToolRegistryCreateDocsView,
          beforeEnter: (_to, _from, next) => {
            restartWizard(next);
          },
          meta: {
            auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
          },
        },
        {
          path: ROUTE.PATH.TOOL_REGISTRY_CREATE_HOLDERS,
          name: ROUTE.NAME.TOOL_REGISTRY_CREATE_HOLDERS,
          component: ToolRegistryCreateHoldersView,
          beforeEnter: (_to, _from, next) => {
            restartWizard(next);
          },
          meta: {
            auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
          },
        },
        {
          path: ROUTE.PATH.TOOL_REGISTRY_CREATE_MAIN_INFO,
          name: ROUTE.NAME.TOOL_REGISTRY_CREATE_MAIN_INFO,
          component: ToolRegistryCreateMainInfoView,
          beforeEnter: (_to, _from, next) => {
            restartWizard(next);
          },
          meta: {
            auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
          },
        },
        {
          path: ROUTE.PATH.TOOL_REGISTRY_CREATE_TRANSFERABILITY,
          name: ROUTE.NAME.TOOL_REGISTRY_CREATE_TRANSFERABILITY,
          component: ToolRegistryCreateTransferabilityView,
          beforeEnter: (_to, _from, next) => {
            restartWizard(next);
          },
          meta: {
            auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
          },
        },
        {
          path: ROUTE.PATH.TOOL_REGISTRY_CREATE_TYPE,
          name: ROUTE.NAME.TOOL_REGISTRY_CREATE_TYPE,
          component: ToolRegistryCreateTypeView,
          meta: {
            auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
          },
        },
      ],
    },
    {
      path: ROUTE.PATH.TOOL_REGISTRY_DASHBOARD,
      name: ROUTE.NAME.TOOL_REGISTRY_DASHBOARD,
      component: ToolRegistryDashboardView,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.TOOL_REGISTRY_EDIT,
      component: ToolRegistryEditRouter,
      props: true,
      meta: {
        auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
      },
      children: [
        {
          path: '',
          name: ROUTE.NAME.TOOL_REGISTRY_EDIT,
          component: ToolRegistryEditMainInfoView,
          props: true,
          meta: {
            auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
          },
        },
        {
          path: ROUTE.PATH.TOOL_REGISTRY_EDIT_DOCS,
          name: ROUTE.NAME.TOOL_REGISTRY_EDIT_DOCS,
          component: ToolRegistryEditDocsView,
          props: true,
          meta: {
            auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
          },
        },
        {
          path: ROUTE.PATH.TOOL_REGISTRY_EDIT_HOLDERS,
          name: ROUTE.NAME.TOOL_REGISTRY_EDIT_HOLDERS,
          component: ToolRegistryEditHoldersView,
          props: true,
          meta: {
            auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
          },
        },
        {
          path: ROUTE.PATH.TOOL_REGISTRY_EDIT_MAIN_INFO,
          name: ROUTE.NAME.TOOL_REGISTRY_EDIT_MAIN_INFO,
          component: ToolRegistryEditMainInfoView,
          props: true,
          meta: {
            auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
          },
        },
        {
          path: ROUTE.PATH.TOOL_REGISTRY_EDIT_TRANSFERABILITY,
          name: ROUTE.NAME.TOOL_REGISTRY_EDIT_TRANSFERABILITY,
          component: ToolRegistryEditTransferabilityView,
          props: true,
          meta: {
            auth: ROUTE.AUTH.USER_ROLE.AUTHORIZED,
          },
        },
      ],
    },
  ],
};

export default toolRegistryRoute;

const restartWizard = (next) => {
  const progress = store.getters['toolRegistryCreate/progress'];
  if (progress) {
    next();
  } else {
    next({ name: ROUTE.NAME.TOOL_REGISTRY_CREATE });
  }
};
