<template>
  <v-row>
    <v-col cols="12" md="10" offset-md="1">
      <v-card outlined>
        <v-toolbar flat>
          <v-spacer />
          <v-toolbar-title>
            <slot name="title" />
          </v-toolbar-title>
          <v-spacer />
          <v-btn color="transparent" icon @click="onClose">
            <v-icon color="grey darken-3">{{ mdiCloseThick }}</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <slot name="progress" />
        </v-card-text>
        <v-card-text>
          <slot name="main-content" />
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="12" md="3" offset-md="2">
              <slot name="bottom-action-left" />
            </v-col>
            <v-col cols="12" md="3" offset-md="2">
              <slot name="bottom-action-right" />
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiCloseThick } from '@mdi/js';

export default {
  name: 'TheWizardWindow',
  props: {
    closeRoute: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      mdiCloseThick,
    };
  },
  methods: {
    onClose() {
      this.$router.push(this.closeRoute);
    },
  },
};
</script>
