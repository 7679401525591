<template>
  <span class="actionButtons">
    <v-btn icon :disabled="disabled" @click.stop="onEdit">
      <v-icon color="info">{{ mdiPencilOutline }}</v-icon>
    </v-btn>
    <v-btn icon :disabled="disabled" @click.stop="onDelete">
      <v-icon color="error">{{ mdiDeleteOutline }}</v-icon>
    </v-btn>
  </span>
</template>

<script>
import { mdiDeleteOutline, mdiPencilOutline } from '@mdi/js';

export default {
  name: 'TheManageItemButtons',
  props: {
    itemId: {
      type: String,
      required: true,
      note: 'The item uuid',
    },
    disabled: {
      type: Boolean,
      default: false,
      note: 'Disable the buttons',
    },
  },
  data() {
    return {
      mdiDeleteOutline,
      mdiPencilOutline,
    };
  },
  methods: {
    onDelete() {
      this.$emit('delete', this.itemId);
    },
    onEdit() {
      this.$emit('edit', this.itemId);
    },
  },
};
</script>

<style scoped>
tr .actionButtons {
  visibility: hidden;
}
tr:hover .actionButtons {
  visibility: visible;
}
</style>
