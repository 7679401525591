<template>
  <div class="ma-3">
    <v-alert border="left" outlined prominent text type="error">
      <span>Sorry, an error occurred</span>
    </v-alert>
    <v-expansion-panels flat>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>Detail</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list dense>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="pb-1">
                  <span>Message</span>
                </v-list-item-title>
                <v-list-item-subtitle class="pb-1 text-wrap">
                  <span v-text="message" />
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="pb-1">
                  <span>Code</span>
                </v-list-item-title>
                <v-list-item-subtitle class="pb-1 text-wrap">
                  <span v-text="code" />
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <slot name="actions" />
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { errorHandler } from '@/utils';

export default {
  name: 'TheErrorMessage',
  props: {
    error: {
      type: Error,
      required: true,
      note: 'The error to display',
    },
  },
  computed: {
    message() {
      return this.processedError &&
        this.processedError.data &&
        this.processedError.data.message
        ? this.processedError.data.message
        : 'No message';
    },
    code() {
      return this.processedError && this.processedError.status
        ? this.processedError.status
        : 'No code';
    },
    processedError() {
      return this.error ? errorHandler.process(this.error) : null;
    },
  },
};
</script>
