import { myAssetsApi } from '@/apis';

export default {
  async initMyAssetsDashboard({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchList'), dispatch('fetchSummary')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async incrementAssets({ commit, dispatch }) {
    commit('setAdding', true);
    await dispatch('fetchList');
    commit('setAdding', false);
  },
  async fetchList({ commit }) {
    try {
      const response = await myAssetsApi.requestList();
      commit('addAssets', response.data.assets);
      commit('setPaging', response.data.paging);
    } catch (error) {
      commit('setError', error);
    }
  },
  async fetchSummary({ commit }) {
    try {
      const response = await myAssetsApi.requestSummary();
      commit('setSummary', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
};
