import { fakerIT as faker } from '@faker-js/faker';

const mockTxRegistrySummary = () => {
  const item = {
    forced: faker.number.int({ max: 5, min: 1 }),
    regular: faker.number.int({ max: 50, min: 1 }),
    total: faker.number.int({ max: 50, min: 1 }),
  };
  return item;
};

const mockTxRegistryItem = () => {
  const item = {
    color: faker.internet.color(),
    created_at: faker.date.soon({ refDate: '2024-01-01T00:00:00.000Z' }),
    executed_on: faker.date.recent({ refDate: '2024-02-01T00:00:00.000Z' }),
    from_name: faker.person.fullName(),
    id: faker.string.uuid(),
    to_name: faker.person.fullName(),
    tx_hash: faker.string.alphanumeric(64),
  };
  return item;
};

const mockTxRegistryItems = (items = 3) => {
  return faker.helpers.multiple(mockTxRegistryItem, {
    count: items,
  });
};

export { mockTxRegistrySummary, mockTxRegistryItem, mockTxRegistryItems };
